<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card class="el-main " style="position: relative;">
			<div class="a-flex-rfsc a-line-b-e0 a-ptb-15">
				<span class="a-fs-16 a-fw-700">{{station.name}}</span>
				<el-tag class="a-ml-08">{{ station.statusText }}</el-tag>
			</div>

			<div class="a-mt-40 stepbox">
				<el-steps :active="2" align-center>
					<el-step title="设备出库" :description="station.applyDateText">
						<div style="height:8px;width:8px;" class="a-bg-master a-br-4"></div>
					</el-step>
					<el-step title="平台审核">
						<template #icon>
							<img src="../../assets/icon/wait-icon.png" alt="" style="height:16px;width:16px;">
						</template>
					</el-step>
					<el-step title="建设中">
					</el-step>
				</el-steps>
			</div>

			<span class="a-c-333 a-fs-16 a-fw-b a-mt-40">基本信息</span>

			<div class="a-flex-rfsc a-flex-wrap a-fs-14 a-mt-10" style="max-width: 1200px;">
				<div class="a-flex-rfsfs a-ptb-8" style="width: 400px;">
					<span class=" a-c-999">站点地址</span>
					<span class="a-c-333 a-ml-16 a-flex-1">{{station.address}}</span>
				</div>
				<div class="a-flex-rfsfs a-ptb-8" style="width: 400px;">
					<span class=" a-c-999">地图点位</span>
					<div class="a-ml-16 a-c-blue a-flex-rcc a-cursor-p" @click="handlerShowLocation">
						<span>查看</span>
						<img src="../../assets/icon/option-right-grey.png"
							style="width: 6px; height: 10px;margin-left: 7px;" />
					</div>
				</div>
				<div class="a-flex-rfsfs a-ptb-8" style="width: 400px;">
					<span class=" a-c-999">规划容量(台)</span>
					<span class="a-c-333 a-ml-16 a-flex-1">{{station.capacity}}</span>
				</div>
				<div class="a-flex-rfsfs a-ptb-8" style="width: 400px;">
					<span class=" a-c-999">所属商户</span>
					<le-jumpto-detail url="/franchisee/franchisee-detail" :dQuery="{ 'code': station.companyId }">
						<span class="a-c-blue a-ml-16 a-flex-1">{{ station.companyName }}</span>
					</le-jumpto-detail>
				</div>
				<div class="a-flex-rfsfs a-ptb-8" style="width: 400px;">
					<span class=" a-c-999">运营商户</span>
					<le-jumpto-detail url="/franchisee/franchisee-detail"
						:dQuery="{ 'code': station.operationCompanyId }">
						<span class="a-c-blue a-ml-16 a-flex-1">{{ station.operationCompany }}</span>
					</le-jumpto-detail>
				</div>
				<div class="a-flex-rfsfs a-ptb-8" style="width: 400px;">
					<span class=" a-c-999">申请商户</span>
					<le-jumpto-detail url="/franchisee/franchisee-detail"
						:dQuery="{ 'code': station.submitterCompanyId }">
						<span class="a-c-blue a-ml-16 a-flex-1">{{ station.submitterCompany }}</span>
					</le-jumpto-detail>
				</div>
				<div class="a-flex-rfsfs a-ptb-8" style="width: 400px;">
					<span class=" a-c-999">站点地址</span>
					<span class="a-c-333 a-ml-16 a-flex-1">{{station.city+station.district+station.address}}</span>
				</div>
			</div>
			<template v-if="station.chargingPlanId">
				<span class="a-c-333 a-fs-16 a-fw-b a-mt-40">站点定价</span>

				<div class="a-mt-16">
					<le-price-detail :priceId="station.chargingPlanId" readOnly></le-price-detail>
				</div>
			</template>


			<span class="a-c-333 a-fs-16 a-fw-b a-mt-40">项目收益</span>

			<div class="a-mt-16">
				<div class="a-br-4 a-flex-ccc " style="width: 108px;height: 108px; border: 1px solid #EBF0F5;"
					@click="dialogProjectDetail=true">
					<img src="../../assets/icon/option-project.png" style="width: 40px;height: 40px;" />
					<span class="a-c-333 a-ml-14 a-mt-6">项目资料</span>
				</div>
			</div>

			<template v-if="station.surveyAreaId">
				<span class="a-c-333 a-fs-16 a-fw-b a-mt-40">现勘资料</span>

				<div class="a-flex-rfsc a-mt-16">
					<div style="width: 108px;height: 108px; border: 1px solid #EBF0F5;" class="a-br-4 a-flex-ccc" @click="handlerGoSurveyArea">
						<img src="../../assets/icon/option-survey-area.png" style="width: 40px;height: 40px;" />
						<span class="a-c-333 a-ml-14 a-mt-6" >小区信息</span>
					</div>
					<div style="width: 108px;height: 108px; border: 1px solid #EBF0F5;" class="a-br-4 a-flex-ccc a-ml-24" @click="handlerGoSurveyStation">
						<img src="../../assets/icon/option-survey-station.png" style="width: 40px;height: 40px;" />
						<span class="a-c-333 a-ml-14 a-mt-6" >站点信息</span>
					</div>
				</div>
			</template>

			<div style="display: block;">
				<div style="height: 300px;width: 10px;" ></div>
			</div>
			
			
			<div class="a-line-t-e0 footerBox">
				<template v-if="topCompanyId==company.id">
					<el-button type="primary" class="a-ml-24 a-mt-15" @click="handlerAuthSucc">通过</el-button>
					<el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="authDialogParam.dialogFormVisible =true">拒绝</el-button>
				</template>
				<template v-if="station.companyId==company.id || station.operationCompanyId==company.id || station
				.submitterCompanyId==companyId">
					<el-button type="primary" class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="handlerEdit" v-if="$_has(7)">修改</el-button>
					<el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="handlerCancel">撤站</el-button>
				</template>
			</div>

		</el-card>
		<le-address-map :visible.sync="dialogVisibleMap" :positionObject.sync="positionObject"></le-address-map>
		<el-dialog :visible.sync="dialogProjectDetail">
			<project-detail-base :incomeId="incomeDistri.id"></project-detail-base>
			<project-detail-rate :incomeId="incomeDistri.id"></project-detail-rate>
		</el-dialog>
		<le-examine-dialog :dialogObj="authDialogParam" @submit="handlerAuthFail"></le-examine-dialog>
	</div>

</template>

<script>
	import LeAddressMap from '../components/choose/le-address-map.vue'
	import LePriceDetail from '../components/page/le-price-detail.vue'
	import ProjectDetailRate from '../project/project-detail-rate.vue'
	import ProjectDetailBase from '../project/project-detail-base.vue'
	import LeExamineDialog from '../components/poppup/le-examine-dialog.vue'
	import util from '../../utils/validate';
	import {
		mapState,
		mapActions
	} from 'vuex';
	export default {
		components: {
			'le-address-map': LeAddressMap,
			'le-price-detail': LePriceDetail,
			'project-detail-rate': ProjectDetailRate,
			'project-detail-base': ProjectDetailBase,
			'le-examine-dialog':LeExamineDialog
		},
		data() {

			return {
				stationId: null,
				topCompanyId:null,//站点所属商户的顶级商户ID
				station: {

				},
				incomeDistri: {

				},
				deviceStat: {

				},
				dialogVisibleMap: false,
				positionObject: {
					'lat': '',
					'lng': '',
					'name': '',
					'address': ''
				},
				dialogProjectDetail: false,
				authDialogParam:{
					title:'站点审核',
					dialogFormVisible:false,
					objection:'',
					cancle:'取消',
					sure:'确定'
				}
			}
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		mounted() {
			this.stationId = this.$route.query.stationId
			this.getStationDetail();
		},
		methods: {
			/**
			 * 获取站点详细信息
			 */
			getStationDetail() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getStationDetail,
					params: {
						stationId: this.stationId
					}
				}).then(({
					data
				}) => {
					console.log('getStationDetail', data)
					this.station = data.station
					this.incomeDistri = data.incomeDistri
					this.positionObject = {
						'lat': this.station.lat,
						'lng': this.station.lng,
						'name': this.station.name,
						'address': this.station.address
					}
					this.getTopCompanyId(data.station.companyId)
				})
			},
			/**
			 * 获取顶级
			 */
			getTopCompanyId(stationOwnerCompanyId){
				this.$Axios._get({
					url: this.$Config.apiUrl.getTopCompanyIdWithCompanyId,
					params: {
						companyId: stationOwnerCompanyId,
					}
				}).then(({
					data
				}) => {
					this.topCompanyId = data
				})
			},
			/**
			 * 显示站点地址
			 */
			handlerShowLocation() {
				this.$set(this, 'dialogVisibleMap', true)
			},
			
			handlerAuthSucc(){
				this.$Axios._post({
					url: this.$Config.apiUrl.authStation,
					params: {
						stationId: this.stationId,
						authResult:true
					}
				}).then(({
					data
				}) => {
					this.$message.success("操作成功")
					this.$store.dispatch("remove_current_view", this)
				})
			},
			
			handlerAuthFail(authMsg){
				if(!authMsg){
					this.$message.error("请输入拒绝原因")
				}else{
					this.$Axios._post({
						url: this.$Config.apiUrl.authStation,
						params: {
							stationId: this.stationId,
							authResult:true,
							authMsg:authMsg
						}
					}).then(({
						data
					}) => {
						this.$message.success("操作成功")
						this.$store.dispatch("remove_current_view", this)
					})
				}
			},
			
			/**
			 * 编辑站点
			 */
			handlerEdit(){
				this.$store.dispatch("remove_current_view", this).then(()=>{
					this.$router.push({
						path: '/station/station-add',
						query:{
							stationId:this.station.id
						}
					})
				})
				
				
			},
			
			handlerCancel(){
				this.$confirm('确定撤销该站点?', '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
				    this.$Axios._get({
				        url: this.$Config.apiUrl.cancelCreateStation,
				        params: {
				            stationId: this.station.id
				        }
				    }).then((res) => {
				        this.$message.success("撤站成功")
						this.$store.dispatch("remove_current_view", this)
				    })
				});
			},
			handlerGoSurveyArea(){
				this.$router.push({
					path: '/sceneCheck/sceneCheck-info',
					query:{
						id:this.station.surveyAreaId
					}
				})
			},
			handlerGoSurveyStation(){
				this.$router.push({
					path: '/sceneCheck/sceneCheck-site-info',
					query:{
						surveyAreaId:this.station.surveyAreaId,
						surveyStationId:this.station.surveyStationId
					}
				})
			}
		}
	}
</script>
<style scoped lang="scss">
	.stepbox {
		width: 100%;

		/deep/ .el-steps {
			.el-step {
				.el-step__icon {
					border-color: white;
					width: 40px;

					.el-step__icon-inner {
						height: 8px;
						width: 8px;
						border-radius: 100%;
						font-size: 0px;
						line-height: 14px;
					}
				}

				.el-step__title {
					font-size: 14px;
					color: #909499;
				}

				.el-step__description {
					color: #909499;
					font-size: 14px;
				}

				.is-finish {
					&.el-step__title {
						color: #303133;
					}

					.el-step__icon-inner {
						color: #007AFF;
						background-color: #007AFF;
					}
				}

				.is-process,
				.is-wait {
					.el-step__icon-inner {
						color: #ADB0B3;
						background-color: #ADB0B3;
					}
				}
			}
		}
	}

	// .footerBox {
	// 	background-color: #FFF;
	// 	left: 0px;
	// 	right: 0;
	// 	position: absolute;
	// 	bottom: 0;
	// 	background: #fff;
	// 	margin-bottom: -8px;
	// }
</style>
